import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AboutMe from "../components/about"
import { Preloader, Placeholder } from "react-preloading-screen"
// import LoadingScreen from "../components/loadingScreen"

const About = () => (
  <Preloader fadeDuration={0} className="preloader" style={{
    background: "transparent",
  }}>
    <Layout>
      <SEO title="About Me"
           description="I have been fascinated with front-end development since I started my first business in 2015 which was a video game e-commerce built on angular."/>
      <AboutMe/>
    </Layout>
    <Placeholder>
      {/*<LoadingScreen/>*/}
    </Placeholder>
  </Preloader>
)

export default About
