import React from "react"
import "./about.scss"
import { Container, Row, Col } from "react-bootstrap"
import timeline from "../images/about/timeline.png"
import { motion } from "framer-motion"

const AboutMe = () => {
  return (
    <motion.section id="about" initial={{ scale: 0.5 }} animate={{ scale: 0.96 }}>
      <Container fluid>
        <Row>
          <Col sm={12} lg={6} className="text-container">
            <h1>About me</h1>
            <p>I have been fascinated with software development since I started my first business in 2015 which was a
              video game e-commerce built on angular and symphony. </p>
            <p> I build a variety of web applications from e-commerces based on magento for clients with a small
              time frame to full-stack Blockchain software using React.js and Solidity.</p>
            <p>Additionally, I can help you build your dream static application using Gatsby.js and help you manage it
              yourself using a headless CMS like contentful.</p>
            <img src={timeline} alt="" className="timeline"/>
          </Col>
          <Col md={6} className="pyramid-container">
            <div id="pyramid">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </Col>
        </Row>
      </Container>
    </motion.section>
  )
}

export default AboutMe
